export default class PromisePool {
  private trackingQueue = [] as number[];
  private promiseQueue = [] as Promise<void>[];
  private nextTaskId = 1;

  constructor(public maxBatchSize: number) {
    if (!maxBatchSize) throw new Error("Must specify a max batch size");
    this.maxBatchSize = maxBatchSize;
  }

  toString(): string {
    return this.trackingQueue
      .map(x => x.toString().padStart(3, "0"))
      .join(", ");
  }

  async enqueueProcess<T>(promise: () => Promise<T>) {
    if (this.isFull()) {
      await this.waitForAvailableCapacity();
    }

    const taskId = this.nextTaskId++;

    this.trackingQueue.push(taskId);
    this.promiseQueue.push(promise().then(() => this.removeTaskId(taskId)));
  }

  async waitForAvailableCapacity() {
    await Promise.race(this.promiseQueue);
  }

  async waitForEmpty() {
    await Promise.all(this.promiseQueue);
  }

  private removeTaskId(id: number): void {
    const foundIndex = this.trackingQueue.indexOf(id);

    this.promiseQueue.splice(foundIndex, 1);
    this.trackingQueue.splice(foundIndex, 1);
  }

  isFull(): boolean {
    return this.promiseQueue.length >= this.maxBatchSize;
  }
}
