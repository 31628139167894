



























































































































































import { Component, Inject, Vue } from "vue-property-decorator";
import AppState from "@/store/modules/app-module";
import { getModule } from "vuex-module-decorators";
import Card from "@/components/material/Card.vue";
import FileUploadService from "@/services/file-upload-service";
import PolicyService from "@/services/policy-service";
import { Policy } from "@/models/library-maintenance.d";
import FileDropZone from "@/components/form/file-drop-zone.vue";
import PromisePool from "@/helpers/PromisePool";
import * as xlsx from "xlsx";
import config from "@/config";

const appState = getModule(AppState);
@Component({
  components: {
    Card,
    FileDropZone
  }
})
export default class UploadFromFileView extends Vue {
  @Inject() FileUploadService!: FileUploadService;
  @Inject() PolicyService!: PolicyService;

  file = null as File | null;
  customerMap = null as File | null;

  policies: Policy[] = [];
  selectedPolicies = [] as string[];
  loading = false;

  //consumption plan as a request concurrency limit of 100
  batchSize = 9;
  concurrency = 11;

  process = {
    processing: false,
    cancelled: false,
    errorMessage: "",
    totalRows: 0,
    processedRows: 0,
    totalScreenings: 0,
    sentScreenings: 0,
    doneScreenings: 0,
    complete: false,
    referenceColumnName: "Booking Reference",
    screeningErrors: [] as {
      statusCode: number;
      message: string;
      reference: string;
    }[],
    startTime: null as Date | null,
    eta: null as number | null
  };

  async mounted() {
    this.policies = await this.getPolicies();
    this.pingIngestionEndpoint();
  }

  disableUploadButton() {
    return this.file == null || this.selectedPolicies.length == 0 || this.process.processing;
  }

  disableCancelButton() {
    return !this.process.processing;
  }

  removeFile() {
    this.file = null;
  }

  dropFiles(files: File[]) {
    if (files.length != 1) return;

    this.file = files[0];
  }

  fileError() {
    if (this.file == null) {
      return null;
    }

    if (!this.file.name.endsWith(".xlsx")) {
      return "Not an Excel spreadsheet";
    }
  }

  cancelUpload() {
    this.process.cancelled = true;
  }

  async uploadFile() {
    const file = this.file;
    if (file == null) {
      return;
    }
    this.process.errorMessage = "";

    if (!file.name.endsWith(".xlsx")) {
      this.process.errorMessage = "ERROR: Not an Excel spreadsheet";
      return;
    }

    this.loading = true;

    await this.$nextTick();
    const result = await this.readFile(file);

    await this.$nextTick();
    const workbook = xlsx.read(result);

    await this.$nextTick();
    this.loading = false;

    await this.processWorkbook(workbook);
  }

  readFile(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }

  async processWorkbook(workbook: xlsx.WorkBook) {
    const name = this.firstVisibleSheetName(workbook);

    if (!name) {
      this.process.errorMessage = "ERROR: Invalid spreadsheet";
      return;
    }

    this.process.processing = true;
    this.process.startTime = new Date();

    const sheet = workbook.Sheets[name];

    const conversionOptions: xlsx.Sheet2JSONOpts = {
      blankrows: false,
      header: 1
    };

    const sheetAsJson = xlsx.utils.sheet_to_json(sheet, conversionOptions);

    this.processSheet(name, sheetAsJson as string[][]);
  }

  firstVisibleSheetName(workbook: xlsx.WorkBook): string | null {
    for (const name of workbook.SheetNames) {
      if (!workbook.Workbook) return name;

      const allSheetProps = workbook.Workbook.Sheets;
      if (!allSheetProps) return name;

      const sheetProps = allSheetProps.find(s => s.name == name);
      if (!sheetProps) return name;

      if (!sheetProps.Hidden) return name;
    }

    return null;
  }

  async processSheet(name: string, rows: string[][]) {
    const headings = rows[0];
    rows.splice(0, 1); // remove header row
    const screeningRanges = this.parseRows(name, headings, rows);

    const totalBatches = Math.ceil(screeningRanges.length / this.batchSize);
    let currentBatch = 0;

    this.process.totalRows = rows.length;
    this.process.totalScreenings = screeningRanges.length;
    const taskQueue = new PromisePool(this.concurrency);

    for (let s = 0; s < screeningRanges.length && !this.process.cancelled; s += this.batchSize) {
      const lastBatch = Math.min(screeningRanges.length - 1, s + this.batchSize);
      const start = screeningRanges[s][0];
      const end = screeningRanges[lastBatch][1];

      const batch = rows.slice(start, end + 1);

      this.process.processedRows = end;
      this.process.sentScreenings = lastBatch;

      await taskQueue.enqueueProcess(() => this.sendBatch(headings, batch));

      await this.$nextTick();
      await this.delay(100);
      currentBatch = s / this.batchSize;
      if (currentBatch > 10) this.process.eta = this.calculateEta(currentBatch, totalBatches);
    }

    await taskQueue.waitForEmpty();

    this.process.processedRows = rows.length;
    this.process.sentScreenings = screeningRanges.length;
    this.process.complete = true;
  }

  async sendBatch(headings: string[], batch: string[][]) {
    const response = await this.FileUploadService.SendScreeningBatch(
      headings,
      batch,
      this.selectedPolicies,
      config.fileUploadDefaultMapping
    );

    this.process.doneScreenings += response.length;

    const errors = response
      .filter(x => x.status_code != 202)
      .map(x => ({
        statusCode: x.status_code,
        message: x.response_body,
        reference: x.reference
      }));

    this.process.screeningErrors.splice(0, 0, ...errors);
  }

  delay(milliseconds: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }

  parseRows(name: string, headers: string[], rows: string[][]): [number, number][] {
    const referenceColumn: string = config.fileUploadDefaultMapping.reference;
    const referenceIndex = headers.indexOf(referenceColumn);

    this.process.referenceColumnName = referenceColumn;

    if (referenceIndex < 0) {
      this.process.errorMessage = `ERROR: The sheet '${name}' does not have a column heading ${referenceColumn}`;
      this.process.processing = false;
    }

    const result = [] as [number, number][];
    let currentReference = rows[0][referenceIndex];
    let start = 0;
    let end = 0;
    for (let i = 1; i < rows.length; i++) {
      const row = rows[i];
      if (row[referenceIndex] != currentReference) {
        result.push([start, end]);
        start = i;
        currentReference = row[referenceIndex];
      }
      end = i;
    }

    return result;
  }

  calculateEta(batchNumber: number, totalBatches: number): number | null {
    const startTime = this.process.startTime;
    if (!startTime) return null;

    const elapsed = new Date().getTime() - startTime.getTime();

    const totalTime = (totalBatches * elapsed) / batchNumber;

    return totalTime - elapsed;
  }

  async getPolicies() {
    const pagination = {
      page: 1,
      size: 100,
      sort: []
    };

    const policies = await this.PolicyService.listPolicies(pagination);
    return policies._embedded.policies;
  }

  async pingIngestionEndpoint() {
    this.FileUploadService.WakeUp();
  }

  get color() {
    return appState.apiFault ? "error" : "primary";
  }

  dropZoneStyle(active: boolean) {
    return {
      "min-height": "150px",
      "border-style": active ? "solid" : "dashed",
      "border-color": "#ccc",
      "background-color": active ? "white" : "#eeeeee"
    };
  }
}
