












import { Component, Vue } from "vue-property-decorator";

@Component
export default class FileDropZoneComponent extends Vue {
  active = false;
  timeout?: number;

  setActive() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
    this.active = true;
  }

  setInactive() {
    this.timeout = setTimeout(() => (this.active = false), 50);
  }

  onDrop(event: DragEvent) {
    const data = event.dataTransfer;
    if (!data) return;

    this.setInactive();
    this.$emit("files-dropped", [...data.files]);
  }
}
